<template>
  <div class="flex-flex-center">
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="flip_to_front"
      tooltip="Bring to front"
      @click="$emit('click', { action: action.BRING_TO_FRONT })"
    />
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="flip_to_back"
      tooltip="Send to back"
      @click="$emit('click', { action: action.SEND_TO_BACK })"
    />
  </div>
</template>

<script>
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import { ToolbarAction } from '@/views/diagram/toolbar/toolbarAction';

export default {
  name: 'ZIndexButtons',
  components: { DiagramToolbarBtn },
  props: {
    selectionCount: Number,
  },
  computed: {
    disable() {
      return this.selectionCount === 0;
    },
  },
  data() {
    return {
      action: ToolbarAction,
    };
  },
};
</script>
