<template>
  <div class="flex-flex-center">
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="horizontal_distribute"
      tooltip="Horizontal distribute"
      @click="$emit('click', { action: action.H_DISTRIBUTE })"
    />
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="vertical_distribute"
      tooltip="Vertical distribute"
      @click="$emit('click', { action: action.V_DISTRIBUTE })"
    />
  </div>
</template>

<script>
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import { ToolbarAction } from '@/views/diagram/toolbar/toolbarAction';

export default {
  name: 'DistributeButtons',
  components: { DiagramToolbarBtn },
  props: {
    selectionCount: Number,
  },
  computed: {
    disable() {
      return this.selectionCount < 2;
    },
  },
  data() {
    return {
      action: ToolbarAction,
    };
  },
};
</script>
