<template>
  <div class="flex-flex-center">
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="content_copy"
      tooltip="Copy Selection"
      @click="$emit('click', { action: action.COPY })"
    />
    <DiagramToolbarBtn
      class="q-mr-sm"
      icon="content_paste"
      tooltip="Paste Selection"
      @click="$emit('click', { action: action.PASTE })"
    />
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="content_cut"
      tooltip="Cut Selection"
      @click="$emit('click', { action: action.CUT })"
    />
  </div>
</template>

<script>
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import { ToolbarAction } from '@/views/diagram/toolbar/toolbarAction';

export default {
  name: 'CopyPasteButtons',
  components: { DiagramToolbarBtn },
  props: {
    selectionCount: Number,
  },
  computed: {
    disable() {
      return this.selectionCount === 0;
    },
  },
  data() {
    return {
      action: ToolbarAction,
    };
  },
};
</script>
