import * as go from 'gojs';

const $ = go.GraphObject.make;

export default function paletteSeparatorTemplate() {
  return $(
    go.Node,
    {
      pickable: false,
      selectionAdorned: false,
      locationSpot: go.Spot.Center,
      dragComputation(e: any) {
        e.diagram.currentTool.doCancel();
        return e;
      },
    },
    $(
      go.Panel,
      'Auto',
      {
        margin: new go.Margin(10, 0, 0, 0),
        desiredSize: new go.Size(200, 5),
        cursor: 'none',
      },
      $(go.Shape, 'LineH', { stroke: 'rgba(0, 0, 0, 0.2)', strokeWidth: 1 }),
    ),
  );
}
