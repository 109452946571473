import * as go from 'gojs';
import paletteModel from '@/bridge/palette/paletteModel';
import paletteShapeTemplate from '@/bridge/palette/paletteShapeTemplate';
import paletteNodeTemplate from '@/bridge/palette/paletteNodeTemplate';
import paletteSeparatorTemplate from '@/bridge/palette/paletteSeparatorTemplate';
import { getAvailableShapes, shapeMap } from '@/bridge/settings/shapeSettings';
import { NodeCategory, ShapeCategory } from '@/bridge/enums/partCategories';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import updateBindings from '@/bridge/util/updateBindings';

function addTemplates(palette: go.Diagram) {
  palette.nodeTemplateMap.add(NodeCategory.ELEMENT, paletteNodeTemplate());
  palette.nodeTemplateMap.add(
    NodeCategory.PALETTE_SEPARATOR,
    paletteSeparatorTemplate(),
  );

  getAvailableShapes().forEach((category) => {
    palette.nodeTemplateMap.add(
      (ShapeCategory as any)[category.toUpperCase()],
      paletteShapeTemplate(shapeMap[category]),
    );
  });
}

function addModel(palette: go.Diagram, modelData: AllDiagramSettings) {
  palette.model = go.GraphObject.make(go.GraphLinksModel, {
    modelData,
    nodeKeyProperty: 'id',
    linkKeyProperty: 'id',
    nodeDataArray: paletteModel(),
  });
}

function createPalette(selector: HTMLDivElement | string, md: AllDiagramSettings) {
  const palette = go.GraphObject.make(go.Palette, selector, {
    allowDelete: false,
    allowZoom: false,
    contentAlignment: go.Spot.TopCenter,
    maxSelectionCount: 1,
    autoScale: go.Diagram.Uniform,
    initialAutoScale: go.Diagram.Uniform,
    layout: go.GraphObject.make(go.GridLayout, {
      wrappingColumn: 1,
    }),
    'animationManager.isEnabled': false,
  });

  addTemplates(palette);
  addModel(palette, md);

  return palette;
}

export function updatePaletteElementSize(palette: go.Diagram, md: AllDiagramSettings) {
  palette.model.commit((model) => {
    model.nodeDataArray.forEach((node) => {
      // skip custom shapes
      if (node.category === NodeCategory.ELEMENT) {
        model.set(node, 'size', md.element.size);
      }
    });
  }, null);
}

export function updatePaletteTextSettings(palette: go.Diagram, md: AllDiagramSettings) {
  palette.model.commit((model) => {
    model.nodeDataArray.forEach((node) => {
      // skip custom shapes
      if (node.category === NodeCategory.ELEMENT) {
        model.set(node, 'textStyle', md.element.textStyle);
      }
    });
  }, null);
}

export function initPalette(selector: HTMLDivElement|string, md: AllDiagramSettings) {
  const palette = createPalette(selector, md);

  setTimeout(() => {
    const md2 = { ...md };
    md2.element.color.backgroundColor = 'red';
    palette.model.modelData = md2;
    updateBindings(palette);
  }, 2000);
  // initialize events and other stuff...
  return palette;
}

export function destroyPalette(selector: HTMLDivElement | string) {
  const palette = go.Diagram.fromDiv(selector);
  if (palette) {
    palette.div = null;
  }
}
