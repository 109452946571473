




import Vue from 'vue';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import { initPalette, destroyPalette } from '@/bridge/palette/initPalette';

export default Vue.extend({
  name: 'BitPalette',
  computed: {
    settings(): AllDiagramSettings|null {
      return this.$store.getters['diagramModule/settings'];
    },
  },
  mounted() {
    if (this.settings) {
      initPalette('bitPalette', this.settings);
    }
  },
  destroyed() {
    destroyPalette('bitPalette');
  },
});
