



















import Vue from 'vue';
import { Diagram } from 'gojs';
import { ToolbarAction } from '@/views/diagram/toolbar/toolbarAction';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';

export default Vue.extend({
  name: 'UndoRedoButtons',
  components: { DiagramToolbarBtn },
  computed: {
    diagramDivId(): string {
      return this.$store.getters['diagramModule/diagramDivId'];
    },
  },
  data() {
    return {
      action: ToolbarAction,
      diagram: null as null|Diagram,
    };
  },
  mounted() {
    this.diagram = Diagram.fromDiv(this.diagramDivId);
  },
});
