













































































import Vue from 'vue';
import { Diagram } from 'gojs';
import { addAssetToElementData, removeAssetFromElementData } from '@/bridge/bit/partAsset';
import { updateSelectionData } from '@/bridge/bit/partCustomisation';
import { NodeCategory, LinkCategory } from '@/bridge/enums/partCategories';
import { isShape } from '@/bridge/settings/shapeSettings';
import { DiagramModel } from '@/types/diagram';
import { AssetMapDetails } from '@/bridge/types/diagramModel';
import { CanvasSelection, SelectionType } from '@/bridge/types/canvasSelection';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import DetailsCardRow from '@/components/card/DetailsCardRow.vue';
import PartAsset from '@/views/bit/bitTabs/PartAsset.vue';
import PartCustomisation from '@/views/bit/bitTabs/PartCustomisation.vue';
import ListNotes from '@/views/diagramNotes/ListNotes.vue';
import DiagramLegend from '@/views/diagram/DiagramLegend.vue';

type AssetChangedEvent = {
  asset: AssetMapDetails;
  emitLinkDrawn: boolean;
};

enum Tabs {
  Asset = 'asset',
  DiagramInfo = 'diagramInfo',
  Legend = 'diagramLegend',
  Customisation = 'customisation',
  Notes = 'notes',
}

export default Vue.extend({
  name: 'BitTabs',
  components: {
    DiagramLegend,
    PartCustomisation,
    DetailsCardRow,
    PartAsset,
    ListNotes,
  },
  computed: {
    diagramDivId(): string {
      return this.$store.state.diagramModule.diagramDivId;
    },
    diagram(): Diagram|null {
      return Diagram.fromDiv(this.diagramDivId);
    },
    diagramDetails(): DiagramModel|null {
      return this.$store.state.diagramModule.details;
    },
    isLink(): boolean {
      const [first] = this.selected.selection;
      if (!first) return false;

      // All the selected parts are either connections or dependencies.
      return this.selected.selection.every((p) => {
        if (!p.data || !first.data) return false;

        return p.category === LinkCategory.CONNECTION
          || p.category === LinkCategory.DEPENDENCY;
      });
    },
    isElement(): boolean {
      const [first] = this.selected.selection;
      if (!first) return false;

      return this.selected.selection.every((p) => {
        if (!p.data || !first.data) return false;

        const sameCategory = p.category === NodeCategory.ELEMENT;
        const sameLayer = p.data.group === first.data.group;

        return sameCategory && sameLayer;
      });
    },
    showPartAsset(): boolean {
      if (this.isShape) return false;

      const [first] = this.selected.selection;
      if (!first) return false;

      return this.selected.selection.every((p) => {
        if (!p.data || !first.data) return false;

        const sameCategory = p.category === first.category;
        const sameLayer = p.data.group === first.data.group;
        const sameAsset = p.data.assetId === first.data.assetId;
        return sameCategory && sameLayer && sameAsset;
      });
    },
    isShape(): boolean {
      return this.selected.selection.every((p) => isShape(p.category));
    },
  },
  data() {
    return {
      tab: Tabs.DiagramInfo,
      tabs: Tabs,
      selected: {
        selection: [],
        type: SelectionType.NONE,
      } as CanvasSelection,
      isMultipleSelection: false,
    };
  },
  methods: {
    async selectionChanged(e: CanvasSelection) {
      this.selected = {
        selection: [],
        type: SelectionType.NONE,
      };

      if (e.selection && e.selection.length) {
        this.selected.selection = e.selection;
        this.selected.type = e.type || SelectionType.NONE;
        this.isMultipleSelection = e.selection.length > 1;
      }

      if (!e.selection.length) {
        this.tab = Tabs.DiagramInfo;
        return;
      }
      if (this.showPartAsset) {
        this.tab = Tabs.Asset;
        return;
      }
      this.tab = Tabs.Customisation;
    },
    onAssetChange(event?: AssetChangedEvent) {
      const sel = { ...this.selected };

      if (event) {
        addAssetToElementData(sel, event.asset, event.emitLinkDrawn);
      } else {
        removeAssetFromElementData(sel);
      }

      this.selected = {
        selection: [],
        type: this.selected.type,
      };

      this.$nextTick(() => {
        this.selected = { ...sel };
        this.$emit('save');
      });
    },
    updateData({ key, value }: any) {
      const sel = { ...this.selected };

      updateSelectionData(sel, key, value);

      this.selected = {
        selection: [],
        type: this.selected.type,
      };

      this.$nextTick(() => {
        this.selected = { ...sel };
        this.$emit('save');
      });
    },
  },
  async created() {
    diagramEvents.$on(diagramEvents.SELECTION_CHANGED, this.selectionChanged);
  },
  beforeDestroy() {
    diagramEvents.$off(diagramEvents.SELECTION_CHANGED, this.selectionChanged);
  },
});
