




























import { Link } from 'gojs';
import Vue, { PropType } from 'vue';
import { ResourceType } from '@/types/resource';
import { RequestConfig, SearchResponse } from '@/types/list';
import { ConnectorAssets } from '@/views/bit/linkDrawnUtils';
import List from '@/components/list/List.vue';
import Btn from '@/components/buttons/Btn.vue';
import CreateConnectorFromAssets from '@/views/bit/CreateConnectorFromAssets.vue';

export default Vue.extend({
  name: 'ListExistingConnector',
  components: {
    Btn,
    List,
    CreateConnectorFromAssets,
  },
  props: {
    step: Number,
    assets: {
      type: Object as PropType<ConnectorAssets>,
      required: true,
    },
    link: {
      type: Object as PropType<Link>,
      required: true,
    },
    defaultData: {
      type: Object as PropType<SearchResponse>,
      required: true,
    },
  },
  computed: {
    requestConfig(): RequestConfig {
      const { from, to } = this.assets;
      const qs = `?resourceType=${this.resourceType}&assetFrom=${from}&assetTo=${to}`;
      return {
        url: `project/${this.projectId}/assets/info${qs}`,
      };
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    resourceType(): ResourceType {
      return this.link.category as ResourceType;
    },
  },
  methods: {
    selectConnector(connector: { id: string; label: string; }) {
      this.$emit('select', { connector, link: this.link });
    },
    async onCreate(id: string, callback: () => void) {
      await callback();

      const listRef = this.$refs.list as any;
      if (listRef && Array.isArray(listRef.rows)) {
        const connector = listRef.rows.filter((r: any) => r.id === id)[0];
        if (connector) {
          this.$emit('select', {
            connector,
            link: this.link,
          });
        }
      }
    },
  },
});
