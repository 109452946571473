































import Vue, { PropType, VueConstructor } from 'vue';
import AppModal from '@/components/AppModal.vue';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import ApiService from '@/services/api.service';
import { ResourceType, AssetResourceRequest } from '@/types/resource';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import { ConnectorAssets } from '@/views/bit/linkDrawnUtils';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import ConnectorAssetsPreview from '@/views/bit/ConnectorAssetsPreview.vue';

function getDefaultConnectorModel(type: ResourceType, assets: ConnectorAssets) {
  return {
    type,
    label: '',
    description: '',
    from: assets.from,
    to: assets.to,
  };
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'CreateConnectorFromAssets',
  mixins: [formSubmit],
  components: {
    ConnectorAssetsPreview,
    FieldDescription,
    FieldLabel,
    ApiError,
    Btn,
    AppModal,
  },
  props: {
    assets: {
      type: Object as PropType<ConnectorAssets>,
      required: true,
    },
    resourceType: {
      type: String as PropType<ResourceType>,
      required: true,
    },
  },
  computed: {
    resourceName(): string {
      switch (this.resourceType) {
        case ResourceType.CONNECTION:
          return 'Connection';
        case ResourceType.DEPENDENCY:
          return 'Dependency';
        default:
          return 'Asset';
      }
    },
    request(): FormWrapperRequest {
      return {
        config: {
          data: this.model,
          method: 'post',
          url: `/project/${this.$route.params.projectId}/asset`,
        },
      };
    },
  },
  data() {
    return {
      model: getDefaultConnectorModel(this.resourceType, this.assets),
      assetFrom: {} as AssetResourceRequest,
      assetTo: {} as AssetResourceRequest,
    };
  },
  methods: {
    async open() {
      await this.loadAssets();
      (this.$refs.modal as any).open();
    },
    async loadAssets() {
      const { projectId } = this.$route.params;
      try {
        this.assetFrom = await ApiService.get(`/project/${projectId}/assets/${this.assets.from}`);
        this.assetTo = await ApiService.get(`/project/${projectId}/assets/${this.assets.to}`);
      } catch {
        this.error = 'Could not load the assets';
      }
    },
    async onSubmit() {
      this.error = '';

      if (!this.model.to || !this.model.from) {
        this.error = 'From and To assets are required';
        return;
      }

      try {
        const { id } = await this.submit(this.request, this.$refs.form);
        if (this.hasError) {
          return;
        }
        this.$emit('submit', id);
        (this.$refs.modal as any).close();
        this.notify(`${this.resourceName} created`);
      } catch {
        this.error = 'There was an error. Please try again later.';
      }
    },
    finish() {
      this.model = getDefaultConnectorModel(this.resourceType, this.assets);
      this.assetFrom = {} as AssetResourceRequest;
      this.assetTo = {} as AssetResourceRequest;
      this.error = '';
    },
  },
});
