


























import Vue, { PropType } from 'vue';
import ApiService from '@/services/api.service';
import { ResourceType, AssetResourceRequest } from '@/types/resource';
import { ConnectorAssets } from '@/views/bit/linkDrawnUtils';
import LayerBadge from '@/components/LayerBadge.vue';
import ApiError from '@/components/ApiError.vue';

export default Vue.extend({
  name: 'ConnectorAssetsPreview',
  components: {
    ApiError,
    LayerBadge,
  },
  props: {
    assets: {
      type: Object as PropType<ConnectorAssets>,
      required: true,
    },
    resourceType: {
      type: String as PropType<ResourceType>,
      required: true,
    },
  },
  computed: {
    linkMessage(): string {
      if (this.resourceType === ResourceType.CONNECTION) {
        return 'Is connected with';
      }
      return 'Is dependent on';
    },
  },
  data() {
    return {
      error: '',
      assetFrom: {} as AssetResourceRequest,
      assetTo: {} as AssetResourceRequest,
    };
  },
  methods: {
    async loadAssets() {
      const { projectId } = this.$route.params;
      try {
        this.assetFrom = await ApiService.get(`/project/${projectId}/assets/${this.assets.from}`);
        this.assetTo = await ApiService.get(`/project/${projectId}/assets/${this.assets.to}`);
      } catch {
        this.error = 'Could not load the assets';
      }
    },
  },
  async created() {
    await this.loadAssets();
  },
});
