import * as go from 'gojs';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { FontWeight } from '@/bridge/enums/textStyle';
import { getDefaultElementSettings } from '@/bridge/settings/elementSettings';
import { getFontString } from '@/bridge/settings/common';

const $ = go.GraphObject.make;

export default function paletteNodeTemplate() {
  const { color, size } = getDefaultElementSettings();

  return $(
    go.Node,
    {
      resizable: false,
      selectionAdorned: false,
      locationSpot: go.Spot.Center,
      dragComputation(node: go.GraphObject, pt: go.Point) {
        diagramEvents.$emit(diagramEvents.NODE_DRAG, { node, isPalette: true });
        return pt;
      },
    },
    $(
      go.Panel,
      'Auto',
      {
        padding: 1,
        desiredSize: new go.Size(size.width, size.height),
        name: 'SHAPE',
        cursor: 'pointer',
      },
      new go.Binding('background', '', () => color.borderColor),
      $(
        go.Shape,
        'Rectangle',
        new go.Binding('fill', '', () => color.backgroundColor),
      ),
      $(
        go.TextBlock,
        {
          verticalAlignment: go.Spot.Center,
          font: getFontString({
            fontWeight: FontWeight.NORMAL,
            fontSize: 14,
          }),
        },
        new go.Binding('stroke', '', () => color.textColor),
        new go.Binding('text', 'label'),
      ),
    ),
  );
}
