import * as go from 'gojs';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { getFontString, getVerticalAlignment } from '@/bridge/settings/common';
import { ShapeSettings } from '@/bridge/types/diagramModel';

const $ = go.GraphObject.make;

// For palette
const defaultShapeSize = 30;

function shapeTemplate(shape: ShapeSettings) {
  return [
    $(go.Shape, {
      geometry: go.Geometry.parse(shape.geometry as string, false),
      width: defaultShapeSize,
      height: defaultShapeSize,
      fill: shape.color.backgroundColor,
      stroke: shape.color.borderColor,
      strokeWidth: shape.borderWidth,
    }),
    $(
      go.TextBlock,
      {
        margin: new go.Margin(5, 0),
        font: getFontString({ ...shape.textStyle, fontSize: 14 }),
        stroke: shape.color.borderColor,
        verticalAlignment: getVerticalAlignment(shape.textStyle.verticalAlignment),
        textAlign: shape.textStyle.textAlign,
      },
      new go.Binding('text', 'label'),
    ),
  ];
}

export default function paletteShapeTemplate(shape: ShapeSettings) {
  return $(
    go.Node,
    'Vertical',
    {
      cursor: 'pointer',
      selectionAdorned: false,
      locationSpot: go.Spot.Center,
      desiredSize: new go.Size(120, 80),
      background: 'rgba(255, 255, 255, 0.05)',
      dragComputation(node: go.Part, pt: go.Point) {
        diagramEvents.$emit(diagramEvents.NODE_DRAG, { node, isPalette: true });
        return pt;
      },
    },
    shapeTemplate(shape),
  );
}
