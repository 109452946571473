










































import Vue from 'vue';
import CopyPasteButtons from '@/views/diagram/toolbar/CopyPasteButtons.vue';
import UndoRedoButtons from '@/views/diagram/toolbar/UndoRedoButtons.vue';
import VerticalAlignButtons from '@/views/diagram/toolbar/VerticalAlignButtons.vue';
import HorizontalAlignButtons from '@/views/diagram/toolbar/HorizontalAlignButtons.vue';
import ZIndexButtons from '@/views/diagram/toolbar/ZIndexButtons.vue';
import ToolbarSeparator from '@/views/diagram/toolbar/ToolbarSeparator.vue';
import DiagramZoomSlider from '@/views/diagram/toolbar/ZoomSlider.vue';
import { ToolbarAction } from '@/views/diagram/toolbar/toolbarAction';
import DistributeButtons from '@/views/diagram/toolbar/DistributeButtons.vue';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { CanvasSelection, SelectionType } from '@/bridge/types/canvasSelection';

type ToolbarInput = {
  action: string;
  value?: any;
};

export default Vue.extend({
  name: 'DiagramModelToolbar',
  components: {
    DiagramZoomSlider,
    ToolbarSeparator,
    ZIndexButtons,
    HorizontalAlignButtons,
    VerticalAlignButtons,
    UndoRedoButtons,
    CopyPasteButtons,
    DistributeButtons,
    // GroupButtons,
  },
  data() {
    return {
      anchorElements: false,
      action: ToolbarAction,
      selectionCount: 0,
    };
  },
  methods: {
    onToggle(value: boolean) {
      this.emitInput({ action: this.action.LOCK_ELEMENTS, value });
    },
    emitInput(e: ToolbarInput) {
      this.$emit('input', e);
    },
    setSelectionCount(e: CanvasSelection) {
      if (e.type === SelectionType.NODE && e.selection) {
        this.selectionCount = e.selection.length;
      } else {
        this.selectionCount = 0;
      }
    },
    onSelectionChanged(e: CanvasSelection) {
      this.setSelectionCount(e);
    },
  },
  created() {
    diagramEvents.$on(diagramEvents.SELECTION_CHANGED, this.onSelectionChanged);
  },
  beforeRouteLeave(to, from, next) {
    diagramEvents.$off(diagramEvents.SELECTION_CHANGED, this.onSelectionChanged);
    next();
  },
});
