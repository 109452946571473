import { ElementData } from '@/bridge/types/diagramModel';
import { NodeCategory, ShapeCategory } from '@/bridge/enums/partCategories';
import { getDefaultElementSettings } from '@/bridge/settings/elementSettings';
import { getAvailableShapes, getDefaultShapeSettings } from '@/bridge/settings/shapeSettings';

export default () => {
  const els = getDefaultElementSettings();
  const paletteNodes: Partial<ElementData>[] = [
    {
      label: 'Element',
      category: NodeCategory.ELEMENT,
      group: -1,
      preset: null,
      size: els.size,
      settings: {},
    },
  ];

  paletteNodes.push({
    category: NodeCategory.PALETTE_SEPARATOR,
  });

  getAvailableShapes().forEach((shape) => {
    const category = (ShapeCategory as any)[shape.toUpperCase()];

    paletteNodes.push({
      ...getDefaultShapeSettings(category),
      category,
      group: -1,
      label: shape,
    });
  });

  return paletteNodes;
};
