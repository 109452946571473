<template>
  <div class="flex-flex-center">
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="align_horizontal_left"
      tooltip="Horizontal Align Left"
      @click="$emit('click', { action: action.H_ALIGN_LEFT })"
    />
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="align_horizontal_center"
      tooltip="Horizontal Align Center"
      @click="$emit('click', { action: action.H_ALIGN_CENTER })"
    />
    <DiagramToolbarBtn
      :disable="disable"
      class="q-mr-sm"
      icon="align_horizontal_right"
      tooltip="Horizontal Align Right"
      @click="$emit('click', { action: action.H_ALIGN_RIGHT })"
    />
  </div>
</template>

<script>
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import { ToolbarAction } from '@/views/diagram/toolbar/toolbarAction';

export default {
  name: 'HorizontalAlignButtons',
  components: { DiagramToolbarBtn },
  props: {
    selectionCount: Number,
  },
  computed: {
    disable() {
      return this.selectionCount < 2;
    },
  },
  data() {
    return {
      action: ToolbarAction,
    };
  },
};
</script>
