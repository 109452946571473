



























import * as go from 'gojs';
import Vue, { PropType } from 'vue';
import notify from '@/util/notify';
import autoSave from '@/bridge/base/AutoSave';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import {
  CheckedLink,
  ConnectorAssets,
  emitSelection,
  highlightPart,
  updateLinkData,
  clearHighlighted,
  getConnectorAssets,
  createNewConnector,
  removeLinkFromCanvas,
} from '@/views/bit/linkDrawnUtils';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import ListExistingConnectors from '@/views/bit/ListExistingConnectors.vue';
import { SearchResponse } from '@/types/list';
import ApiService from '@/services/api.service';

export default Vue.extend({
  name: 'SelectExistingConnectors',
  props: {
    diagram: {
      type: Object as PropType<go.Diagram>,
      required: true,
    },
  },
  components: {
    ApiError,
    AppModal,
    ListExistingConnectors,
  },
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    versionId(): string|null {
      const version = this.$store.getters['diagramModule/version'];
      if (version) {
        return version.id;
      }
      return '';
    },
  },
  data() {
    return {
      step: 0,
      removeLinks: [] as number[],
      checkedLinks: [] as CheckedLink[],
      errors: {} as Record<string, string>,
    };
  },
  methods: {
    onLinkDrawn(e: go.DiagramEvent) {
      this.resetState();
      const tmp: go.Link[] = [];

      if (e.subject instanceof go.Link) {
        tmp.push(e.subject);
      } else if (e.subject.iterator) {
        const it = e.subject.iterator as go.Iterator<go.Link>;
        while (it.next()) {
          tmp.push(it.value);
        }
      }

      if (tmp.length) {
        setTimeout(async () => {
          await this.showModal(tmp);
        }, 100);
      }
    },
    async checkLinks(links: go.Link[]): Promise<CheckedLink[]> {
      const checkedLinks: CheckedLink[] = [];

      for (let index = 0; index < links.length; index += 1) {
        const link = links[index];
        const assets: ConnectorAssets = getConnectorAssets(link);

        // Draw a link - the user can add an assets and connector later
        if (!assets.from || !assets.to) {
          this.removeLinks.push(index);
        } else if (assets.from === assets.to) {
          // Not valid remove the link from the selection and from the canvas
          notify.info(`Invalid ${link.category}. Same assets.`);
          removeLinkFromCanvas(link);
          this.removeLinks.push(index);
        } else {
          try {
            // eslint-disable-next-line no-await-in-loop
            const connectors = await this.searchExistingConnectors(link, assets);

            if (!connectors || !connectors.results.length) {
              // eslint-disable-next-line no-await-in-loop
              await this.createConnector(link);
              this.removeLinks.push(index);
            } else {
              checkedLinks.push({
                link,
                assets,
                connectors,
              });
            }
          } catch (e) {
            this.removeLinks.push(index);
            this.errors[link.key as string] = `There was an error (i: ${index} | l: ${link.key} | ${(e as any).message || 'Loop Error'})`;
          }
        }
      }

      return checkedLinks;
    },
    async searchExistingConnectors(link: go.Link, assets: ConnectorAssets) {
      const qs = `?resourceType=${link.category}&assetFrom=${assets.from}&assetTo=${assets.to}`;
      const linkId = link.key as string;

      try {
        const resp: SearchResponse = await ApiService.get(`project/${this.projectId}/assets/info${qs}`);
        if (resp.results && resp.results.length) {
          return resp;
        }
      } catch (e) {
        this.errors[linkId] = 'Search Connectors Error. Please try again later';
        removeLinkFromCanvas(link);
        return null;
      }

      return null;
    },
    async createConnector(link: go.Link) {
      const linkId = link.key as string;

      // No connectors. Try to create one.
      try {
        await createNewConnector(link, `/project/${this.projectId}/diagram/${this.diagramId}/version/asset?versionId=${this.versionId}`);
        autoSave.$emit(autoSave.EVENT_SAVE);
        emitSelection(this.diagram);
      } catch (e) {
        this.errors[linkId] = 'Create Connector Error. Please try again later';
        removeLinkFromCanvas(link);
      }
    },
    async showModal(links: go.Link[]) {
      this.checkedLinks = await this.checkLinks(links);

      if (this.checkedLinks.length) {
        // eslint-disable-next-line vue/no-mutating-props
        highlightPart(this.checkedLinks[0].link);
        (this.$refs.modal as any).open();
      } else {
        this.resetState();
      }

      const keys = Object.keys(this.errors);
      if (keys.length) {
        notify.danger(`There was an error. ${keys.length} connectors affected.`);
      }
    },
    nextStep() {
      if (this.step < this.checkedLinks.length - 1) {
        this.step += 1;
        highlightPart(this.checkedLinks[this.step].link);
      } else {
        this.resetState();
      }
    },
    onRemove(step: number) {
      const data = this.checkedLinks[step];

      removeLinkFromCanvas(data.link);
      this.nextStep();
    },
    onSelect({ link, connector }: { link: go.Link, connector: any }) {
      if (this.diagram) {
        emitSelection(this.diagram);
        updateLinkData(link, connector);
        autoSave.$emit(autoSave.EVENT_SAVE);
      }

      if (this.checkedLinks.length === 1) {
        this.resetState();
      } else {
        this.nextStep();
      }
    },
    resetState() {
      (this.$refs.modal as any).close();
      this.checkedLinks = [];
      this.removeLinks = [];
      this.errors = {};
      this.step = 0;
      clearHighlighted(this.diagram);
    },
  },
  async created() {
    diagramEvents.$on(diagramEvents.LINK_DRAWN, this.onLinkDrawn);
  },
  destroyed() {
    diagramEvents.$off(diagramEvents.LINK_DRAWN, this.onLinkDrawn);
  },
});
