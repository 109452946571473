import * as go from 'gojs';
import { layerUtils } from '@/bridge/base/layerInfo';
import { NodeCategory, LinkCategory } from '@/bridge/enums/partCategories';
import { capitalizeAllWords } from '@/util/strings';
import { AssetMapDetails } from '@/bridge/types/diagramModel';
import { CanvasSelection } from '@/bridge/types/canvasSelection';

function canHaveAsset(part: go.Part): boolean {
  return [NodeCategory.ELEMENT, LinkCategory.DEPENDENCY, LinkCategory.CONNECTION]
    .includes(part.category as any);
}

export function addAssetToElementData(
  selected: CanvasSelection,
  asset: AssetMapDetails,
  emitLinkDrawn = true,
) {
  const [part] = selected.selection;
  if (!part) return;

  const { diagram }: { diagram: go.Diagram } = part;
  if (!diagram) return;

  diagram.commit((d) => {
    const m = d.model;

    selected.selection.forEach((p: go.Part) => {
      if (!canHaveAsset(p)) return;

      m.set(p.data, 'assetId', asset.id);
      m.set(p.data, 'label', asset.label);

      // Emit is true by default.
      // When we update an asset we set emit to false, to skip LinkDrawn event
      if (emitLinkDrawn && p.category === NodeCategory.ELEMENT) {
        const connectedLinks = (p as go.Node).findLinksConnected();
        if (connectedLinks.count) {
          p.diagram?.raiseDiagramEvent('LinkDrawn', connectedLinks);
        }
      }
    });
  }, null);
}

export function removeAssetFromElementData(selected: CanvasSelection) {
  const [part] = selected.selection;
  if (!part) return;

  const { diagram }: { diagram: go.Diagram } = part;
  if (!diagram) return;

  diagram.commit((d) => {
    const m = d.model;

    selected.selection.forEach((p: go.Part) => {
      if (!canHaveAsset(p)) return;

      if (p.category === NodeCategory.ELEMENT) {
        // remove link asset
        (p as go.Node).findLinksConnected().each((l) => {
          m.set(l.data, 'assetId', null);
          m.set(l.data, 'label', '');
        });
      }

      m.set(p.data, 'assetId', null);
      m.set(p.data, 'label', capitalizeAllWords(layerUtils.layers[p.data.group]));
    });
  }, null);
}
