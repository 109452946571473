export enum ToolbarAction {
  UNDO = 'undo',
  REDO = 'redo',
  COPY = 'copy',
  PASTE = 'paste',
  CUT = 'cut',
  BRING_TO_FRONT = 'bringToFront',
  SEND_TO_BACK = 'sendToBack',
  V_ALIGN_TOP = 'vAlignTop',
  V_ALIGN_CENTER = 'vAlignCenter',
  V_ALIGN_BOTTOM = 'vAlignBottom',
  H_ALIGN_LEFT = 'hAlignLeft',
  H_ALIGN_CENTER = 'hAlignCenter',
  H_ALIGN_RIGHT = 'hAlignRight',
  H_DISTRIBUTE = 'hDistribute',
  V_DISTRIBUTE = 'vDistribute',
  CREATE_DEPENDENCY = 'createDependency',
  LOCK_ELEMENTS = 'lockElements',
  SAVE_DIAGRAM = 'saveDiagram',
  GROUP_SELECTED = 'groupSelected',
  UNGROUP_SELECTED = 'ungroupSelected',
}
