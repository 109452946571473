import { DiagramCheckpoint } from '@/types/diagram';
import { addModel } from '@/bridge/base/initCanvas';

export function importJson(diagram: go.Diagram, checkpoint: DiagramCheckpoint) {
  return new Promise((resolve) => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.onchange = (e) => {
      const fr = new FileReader();
      fr.addEventListener('load', async (evt) => {
        try {
          if (diagram && evt.target && typeof evt.target.result === 'string') {
            addModel(diagram, {
              ...checkpoint,
              model: JSON.parse(evt.target.result),
            });
            resolve(true);
          }
        } catch (err) {
          console.log('there was an error: ', err);
        }
      });
      if (e.target) {
        fr.readAsText((e.target as any).files[0]);
      }
    };
    inputElement.click();
  });
}
