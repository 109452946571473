











































































import Vue from 'vue';
import { Diagram } from 'gojs';
import { DCH } from '@/bridge/types/drawCommandHandler.d';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';
import { DiagramModel, DiagramVersion } from '@/types/diagram';
import { AllDiagramSettings, JSONModel } from '@/bridge/types/diagramModel';
import autoSave from '@/bridge/base/AutoSave';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { ToolbarAction } from '@/views/diagram/toolbar/toolbarAction';
import { anchorElements } from '@/bridge/element/anchorElements';
import { DiagramStatus } from '@/bridge/enums/diagramOptions';
import { importJson } from '@/bridge/print/importJson';
import DiagramCanvas from '@/views/diagram/DiagramCanvas.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import PageSpinner from '@/components/PageSpinner.vue';
import UnsavedChangesModal from '@/views/diagram/UnsavedChangesModal.vue';
import DiagramModelToolbar from '@/views/diagram/toolbar/DiagramModelToolbar.vue';
import DiagramSettingsForm from '@/views/diagram/DiagramSettingsForm.vue';
import UpdateDiagramSettingsBanner from '@/views/diagram/UpdateDiagramSettingsBanner.vue';
import BitPalette from '@/views/diagram/BitPalette.vue';
import BitTabs from '@/views/bit/bitTabs/BitTabs.vue';
import SelectExistingConnectors from '@/views/bit/SelectExistingConnectors.vue';
import ShareDiagram from '@/views/diagram/toolbar/ShareDiagram.vue';
import ExportDiagram from '@/views/diagram/toolbar/ExportDiagram.vue';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import notify from '@/util/notify';
import ApiService from '@/services/api.service';

function getGapDistance(defaultDistance: number) {
  // eslint-disable-next-line no-alert
  const p = prompt('Gap?', `${defaultDistance}`);
  let dist = 0;
  if (p) {
    dist = parseInt(p, 10);
  }

  if (!p || Number.isNaN(dist)) {
    dist = defaultDistance;
  }

  return dist;
}

export default Vue.extend({
  name: 'ModelBit',
  components: {
    DiagramToolbarBtn,
    BitTabs,
    BitPalette,
    DiagramCanvas,
    PageSpinner,
    Breadcrumbs,
    UnsavedChangesModal,
    DiagramModelToolbar,
    DiagramSettingsForm,
    SelectExistingConnectors,
    UpdateDiagramSettingsBanner,
    ShareDiagram,
    ExportDiagram,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    diagramDetails(): DiagramModel|null {
      return this.$store.getters['diagramModule/details'];
    },
    diagramModel(): JSONModel|null {
      return this.$store.getters['diagramModule/model'];
    },
    settings(): AllDiagramSettings|null {
      return this.$store.getters['diagramModule/settings'];
    },
    projectSettings(): AllDiagramSettings|null {
      return this.$store.getters['projectModule/canvasSettings'];
    },
    version(): DiagramVersion|null {
      return this.$store.getters['diagramModule/version'];
    },
    isPublished(): boolean {
      if (this.version && this.version.status) {
        return this.version.status === DiagramStatus.PUBLISHED;
      }
      return false;
    },
    breadcrumbs(): any[] {
      const { versionId, diagramId } = this.$route.params;
      let diagramLabel = '';

      if (this.diagramDetails) {
        diagramLabel = this.diagramDetails.label;
        if (this.diagram && this.diagram.isModified) {
          diagramLabel = `${diagramLabel}*`;
        }
      }

      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
          url: `/org/${this.org.id}/project/${this.project.id}/bits`,
        },
        {
          label: 'View Diagram',
          url: `/org/${this.org.id}/project/${this.project.id}/view-bit/${diagramId}/version/${versionId}`,
        },
        {
          label: `Model ${diagramLabel}`,
        },
      ];
    },
  },
  data() {
    return {
      error: '',
      loading: false,
      diagramDivId: 'modelBit',
      diagram: null as null|Diagram,
      interval: null as any,
    };
  },
  methods: {
    async importDiagram() {
      if (this.diagram && this.version) {
        await importJson(this.diagram, this.version.checkpoint);
        await this.saveDiagram();
      }
    },
    async saveDiagram() {
      if (this.diagram) {
        await this.$store.dispatch('diagramModule/saveDiagram', {
          diagram: this.diagram,
          params: this.$route.params,
        });
        this.diagram.isModified = false;
      }
    },
    async saveDiagramSettings(s: AllDiagramSettings) {
      if (this.diagram) {
        await this.$store.dispatch('diagramModule/saveSettings', {
          diagram: this.diagram,
          settings: s,
          params: this.$route.params,
        });
        await this.$store.dispatch('diagramModule/loadDetails', { ...this.$route.params, force: true });
        this.diagram.isModified = false;

        diagramEvents.$emit(diagramEvents.UPDATE_LEGEND_MODEL);
      }
    },
    async onToolbarInput(e: { action: ToolbarAction, value?: any }) {
      if (this.diagram) {
        const command = this.diagram.commandHandler as DCH;
        switch (e.action) {
          case ToolbarAction.UNDO:
            this.diagram.undoManager.undo();
            break;
          case ToolbarAction.REDO:
            this.diagram.undoManager.redo();
            break;
          case ToolbarAction.COPY:
            command.copySelection();
            break;
          case ToolbarAction.PASTE:
            command.pasteFromClipboard();
            break;
          case ToolbarAction.CUT:
            command.cutSelection();
            break;
          case ToolbarAction.BRING_TO_FRONT:
            command.pullToFront();
            break;
          case ToolbarAction.SEND_TO_BACK:
            command.pushToBack();
            break;
          case ToolbarAction.V_ALIGN_TOP:
            command.alignTop();
            break;
          case ToolbarAction.V_ALIGN_CENTER:
            command.alignCenterY();
            break;
          case ToolbarAction.V_ALIGN_BOTTOM:
            command.alignBottom();
            break;
          case ToolbarAction.H_ALIGN_LEFT:
            command.alignLeft();
            break;
          case ToolbarAction.H_ALIGN_CENTER:
            command.alignCenterX();
            break;
          case ToolbarAction.H_ALIGN_RIGHT:
            command.alignRight();
            break;
          case ToolbarAction.H_DISTRIBUTE:
            command.alignRow(getGapDistance(this.diagram.grid.gridCellSize.width));
            break;
          case ToolbarAction.V_DISTRIBUTE:
            command.alignColumn(getGapDistance(this.diagram.grid.gridCellSize.height));
            break;
          case ToolbarAction.LOCK_ELEMENTS:
            anchorElements(this.diagram, e.value);
            break;
          case ToolbarAction.SAVE_DIAGRAM:
            await this.saveDiagram();
            break;
          default:
            break;
        }
      }
    },
    async toggleFullScreen() {
      const el = document.querySelector('.q-page-container');

      if (el) {
        await this.$q.fullscreen.toggle(el);
      }
    },
    onCanvasLoaded() {
      this.diagram = Diagram.fromDiv(this.diagramDivId);

      window.onbeforeunload = () => {
        if (this.diagram && !this.diagram.isModified) {
          // eslint-disable-next-line no-void
          return void 0;
        }
        return true;
      };
    },
    async onSessionExpired() {
      window.onbeforeunload = null;
      if (this.diagram) {
        this.diagram.isModified = false;
      }
      await this.$router.replace('/logout');
    },
    async redirectToView() {
      const { diagramId, versionId } = this.$route.params;
      await this.$router.replace(`/org/${this.org.id}/project/${this.project.id}/view-bit/${diagramId}/version/${versionId}`);
    },
    async navigateAway() {
      // no diagram lock acquired and user should be redirected away, ignoring unsaved changes
      if (this.diagram) {
        this.diagram.isModified = false;
        window.onbeforeunload = null;
      }
      await this.redirectToView();
    },
    async acquireLock() {
      const { diagramId, projectId, versionId } = this.$route.params;
      let url = `/project/${projectId}/diagrams/${diagramId}/lock`;
      if (versionId) {
        url = `${url}?versionId=${versionId}`;
      }
      await ApiService.put(url);
    },
    async refreshLock() {
      const { diagramId, projectId, versionId } = this.$route.params;
      let url = `/project/${projectId}/diagrams/${diagramId}/relock`;
      if (versionId) {
        url = `${url}?versionId=${versionId}`;
      }
      try {
        await ApiService.put(url);
      } catch (e) {
        notify.danger('Couldn\'t maintain lock on diagram, the diagram has been unlocked.');
        await this.navigateAway();
      }
    },
    async releaseLock() {
      const { diagramId, projectId, versionId } = this.$route.params;
      let url = `/project/${projectId}/diagrams/${diagramId}/unlock`;
      if (versionId) {
        url = `${url}?versionId=${versionId}`;
      }

      try {
        await ApiService.put(url);
      } catch (e) {
        console.log('could not release lock');
      }
    },
  },
  async created() {
    this.$store.dispatch('diagramModule/clear');

    // The user doesn't have the right role.
    // Don't load anything, just redirect.
    if (!this.$hasProjectRole(this.$Roles.CONTRIBUTOR)) {
      await this.redirectToView();
      return;
    }

    await this.$store.dispatch('diagramModule/loadDetails', { ...this.$route.params, force: true });
    this.$store.dispatch('diagramModule/setDiagramDivId', this.diagramDivId);

    // The user has the required role.
    // Check if the diagram can be modified.
    if (this.isPublished) {
      await this.redirectToView();
      return;
    }

    this.$auth.$on('sessionExpired', this.onSessionExpired);
    try {
      await this.acquireLock();
    } catch {
      notify.danger('Couldn\'t acquire lock on diagram, another user is currently modelling.');
      await this.navigateAway();
      return;
    }

    this.interval = setInterval(() => {
      this.refreshLock();
    }, 5000);

    autoSave.$on(autoSave.EVENT_SAVE, this.saveDiagram);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  beforeRouteLeave(to, from, next: () => void) {
    clearInterval(this.interval);
    this.$auth.$off('sessionExpired', this.onSessionExpired);
    autoSave.$off(autoSave.EVENT_SAVE, this.saveDiagram);

    // IMPORTANT! Do not unregister event listeners
    // below this line. It will lead to a potential
    // memory leak depending on the state of the diagram.
    if (this.diagram && this.diagram.isModified) {
      (this.$refs.unsavedChangesModal as any).open(next);
      return;
    }
    this.$store.dispatch('diagramModule/clear');
    this.releaseLock();

    window.onbeforeunload = null;
    next();
  },
});
